import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSignatorySocietyRequest, deleteSignatorySocietyRequest, deleteSocietyRequest, getCatalogSocietiesRequest, getSignatoriesRequest, updateSignatorySocietyRequest } from '../../../../signature-api';

export const getSignatories = createAsyncThunk('signatories/getSignatories', async (SignatoryName) => {

  return await getSignatoriesRequest(SignatoryName);
});

export const getCatalogSocieties = createAsyncThunk('signatories/catalog', async (signatoryyName) => {

  return await getCatalogSocietiesRequest(signatoryyName);
});

export const createSignatory = createAsyncThunk('signatories/createSignatory', async (signatory) => {

  return await createSignatorySocietyRequest(signatory);
});

export const updateSignatory = createAsyncThunk('signatories/updateSignatory', async (signatory) => {

  return await updateSignatorySocietyRequest(signatory);
});

export const deleteSignatory = createAsyncThunk('signatories/deleteSignatory', async (SignatoryId) => {
  try {

    return await deleteSignatorySocietyRequest(SignatoryId);
  } catch (e) {
    console.log("Error ", e)
  }
});