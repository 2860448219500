import { createSlice } from '@reduxjs/toolkit'
import { createSociety, deleteSociety, getSocieties, updateSociety } from './thunks'



const initialState = {
    list: [],
    loading: false,
    error: null
}

export const societiesSlice = createSlice({
  initialState,
  name: 'societies',
  reducers: {
    setInitialData: (state) => {
      state.data = []
    }
  },
  extraReducers: builder =>
  builder
  .addCase(getSocieties.fulfilled, (state, action) => {
    state.list = action.payload
    state.isPending = false
  })
  .addCase(createSociety.fulfilled, (state, action) => {
    state.list.push(action.payload)
    state.isPending = false
  })
  .addCase(updateSociety.fulfilled, (state, action) => {
    const index = state.list.findIndex((society) => society.id === action.payload.id);
    if (index !== -1) {
      state.list[index] = action.payload;
    }
  })
  .addCase(deleteSociety.fulfilled, (state, action) => {
    state.loading = false;
    state.list = state.list.filter((society) => society.id !== action.payload);
  })
})

export const { setInitialData, setOpenModal } = societiesSlice.actions

export default societiesSlice.reducer