// ** Reducers Imports
import layout from "./layout"
import navbar from "./navbar"
import auth from './authentication'
import transactions from '@src/views/pages/invoices/store'
import customerMaster from '@src/views/pages/customer-master/store'
import customerMasterLatitude from '@src/views/pages/customer-master-latitude/store'
import productServiceMaster from '@src/views/pages/product-services-master/store'
import businessManager from "../views/pages/business-manager/store"
import invoiceIssuance from "../views/pages/invoice-issuance/store"
import societies from "../views/pages/societies/store"
import signatories from "../views/pages/signatories-societies/store"

const rootReducer = { 
    navbar, 
    layout, 
    auth, 
    transactions, 
    customerMaster, 
    productServiceMaster, 
    customerMasterLatitude, 
    societies,
    signatories,
    invoiceIssuance, 
    businessManager }

export default rootReducer