import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSocietyRequest, deleteSocietyRequest, getSocietiesRequest, updateSocietyRequest } from '../../../../signature-api';

export const getSocieties = createAsyncThunk('societies/getSocieties', async (societyName) => {

  return await getSocietiesRequest(societyName);
});

export const createSociety = createAsyncThunk('societies/createSociety', async (society) => {

  return await createSocietyRequest(society);
});

export const updateSociety = createAsyncThunk('societies/updateSociety', async (society) => {

  return await updateSocietyRequest(society);
});

export const deleteSociety = createAsyncThunk('societies/deleteSociety', async (societyId) => {
  try {

    return await deleteSocietyRequest(societyId);
  } catch (e) {
    console.log("Error ", e)
  }
});