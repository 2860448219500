import { createSlice } from '@reduxjs/toolkit'
import { createSignatory, deleteSignatory, getCatalogSocieties, getSignatories, updateSignatory } from './thunks'



const initialState = {
    list: [],
    societies: [],
    loading: false,
    error: null
}

export const signatoriesSlice = createSlice({
  initialState,
  name: 'signatories',
  reducers: {
    setInitialData: (state) => {
      state.data = []
    }
  },
  extraReducers: builder =>
  builder
  .addCase(getSignatories.fulfilled, (state, action) => {
    state.list = action.payload
    state.isPending = false
  })
  .addCase(getCatalogSocieties.fulfilled, (state, action) => {

    state.societies = action.payload
    state.isPending = false
  })
  .addCase(createSignatory.fulfilled, (state, action) => {
    state.list.push(action.payload)
    state.isPending = false
  })
  .addCase(updateSignatory.fulfilled, (state, action) => {
    const index = state.list.findIndex((society) => society.id === action.payload.id);
    if (index !== -1) {
      state.list[index] = action.payload;
    }
  })
  .addCase(deleteSignatory.fulfilled, (state, action) => {
    state.loading = false;
    state.list = state.list.filter((society) => society.id !== action.payload);
  })
})

export const { setInitialData, setOpenModal } = signatoriesSlice.actions

export default signatoriesSlice.reducer