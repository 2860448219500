//import { transformMHProductsToLocal } from "../utility/utils";

import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;

//Society Enpoints

export const getSocietiesRequest = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/societies?name=a`)
    console.log("response: ", response)
    return response.data.data
  } catch {

  }
};

export const createSocietyRequest = async (society) => {
  try {
    const response = await axios.post(`${BASE_URL}/societies`, society)
    return response.data.data
  } catch {

  }
};

export const updateSocietyRequest = async ( society ) => {
  try {
    const response = await axios.put(`${BASE_URL}/societies/${society.id}`, society)
    return response.data.data
  } catch {

  }
};

export const deleteSocietyRequest = async ( societyId ) => {
  try {
    const response = await axios.delete(`${BASE_URL}/societies/${societyId}`)

    return societyId
  } catch {
    console.log("Error en el request")
  }
};

//Signatory Endpoints:

export const getSignatoriesRequest = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/signatoriesSocieties`)
    return response.data.data
  } catch {

  }
};

export const getCatalogSocietiesRequest = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/societies/catalog`)
    console.log("response catalog: ", response)
    return response.data
  } catch {

  }
};

export const createSignatorySocietyRequest = async (signatory) => {
  try {
    console.log("signatory request: ", signatory)
    const response = await axios.post(`${BASE_URL}/signatoriesSocieties`, {...signatory, societyId: signatory?.societyId.value})
    return response.data.data
  } catch {

  }
};

export const updateSignatorySocietyRequest = async ( signatory ) => {
  try {
    const response = await axios.put(`${BASE_URL}/signatoriesSocieties/${signatory.id}`, {...signatory, societyId: signatory?.societyId.value})
    return response.data.data
  } catch {

  }
};

export const deleteSignatorySocietyRequest = async ( signatoryId ) => {
  try {
    const response = await axios.delete(`${BASE_URL}/signatoriesSocieties/${signatoryId}`)

    return signatoryId
  } catch {
    console.log("Error en el request")
  }
};